/*
* ===================================================
*     SVG blobs
* ===================================================
*/

.svg-blob {
    position: absolute;
    width: 300px;
    height: 300px;
    fill: currentColor;
}

.svg-blob-fill-current {
    --layer1: currentColor;
}

.svg-blob-swiper {
    top: 0;
    right: -100px;
    width: 100%;
    max-height: 100%;
    z-index: -1;
}