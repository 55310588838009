/*
*
* =====================
* NAVBAR
* =====================
*
*/
.navbar {
    z-index: 4;


    .navbar-brand {
        position: absolute;
        top: 50%;
        left: 50%;
        transition: color 0.3s;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(md) {
            position: static;
            transform: none;
        }
    }

    .d-brand-partial {
        @media (min-width: 400px) {
            display: inline !important;
        }
    }
}

.nav-link-icon {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;

    .notification-badge {
        top: 1rem;
    }

    .notification-badge.notification-badge-number {
        top: .6rem;
    }
}

.input-group-navbar {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;

    .form-control {
        background: $gray-200;
        border: none;
        box-shadow: none;
        font-size: $font-size-sm;
    }

    .btn {
        background: $gray-200;
    }

}

#searchDropdown {
    width: 100%;
}